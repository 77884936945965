<template>
  <div id="Live">
    
    <BarCom>
      <template v-slot:header>
        <div class="item text pointer" :class="{active: filter=='1t'}" @click="$clickSound();setFilter('1t')">1т</div>
        <div class="item text pointer" :class="{active: filter=='2t'}" @click="$clickSound();setFilter('2t')">2т</div>
        <div class="item text pointer" :class="{active: filter=='break'}" @click="$clickSound();setFilter('break')">пер</div>
        <div class="item icon pointer" :class="{active: filter=='favorites'}" @click="$clickSound();setFilter('favorites')"><i class="fas fa-star"></i></div>
      </template>
      <template v-slot:shade>
        
      </template>
    </BarCom>

    <table class="datatable loading" v-if="matches">
      <thead>
        <tr>
          <th colspan="5" class="first"><i class="fas fa-star clear-favorites" title="Очистить избранное" v-if="favorites.length" @click="$clickSound();star()"></i></th>
          <td class="separator"></td>
          <th title="Удары в створ / Удары в сторону ворот" colspan="2">створ</th>
          <td class="separator"></td>
          <th title="Угловые" colspan="2">угл</th>
          <td class="separator"></td>
          <td class="separator"></td>
          <th title="Атаки опасные / Атаки" colspan="2">атаки</th>
          <td class="separator"></td>
          <th title="Владение мячом" colspan="2">%</th>
          <td class="separator"></td>
          <td class="separator"></td>
          <th title="Желтые карточки" colspan="2">жк</th>
          <td class="separator"></td>
          <th title="Пенальти" colspan="2">пен</th>
          <td class="separator"></td>
          <td class="separator"></td>
          <th title="+ТБ 0.5">+0.5</th>
          <th title="+ТБ 1">+1</th>
          <td class="separator"></td>
          <td class="separator"></td>
          <th title="Победа 1">1</th>
          <th title="Ничья">X</th>
          <th title="Победа 2">2</th>
          <td class="separator"></td>
          <th title="Тотал больше">ТБ</th>
          <th title="Тотал меньше">ТМ</th>
        </tr>
      </thead>
      <tbody v-for="m in matches" :key="m.id" >
        <tr class="separator">
          <td colspan="5" class="league">{{ m.league }}</td>
          <td v-for="i in 31" :key="i"></td>
        </tr>
        <tr class="match" :class="{ end: m.end }">
          <td class="date pointer" title="В избранное" @click="$clickSound();star(m.id)">{{ m.dt }}<i class="fas fa-star favorites" v-if="favorites.includes(m.id)"></i></td>
          <td class="time">{{ m.time }}</td>
          <td class="status">{{ m.status }}</td>
          
          <td class="teams">
            <div class="team team1">
              {{ m.team1_name }}
              <div class="rca" v-if="m.t1.rca && m.t1.rca!='0'">{{ m.t1.rca }}</div>
            </div>
            <div class="team team2">
              {{ m.team2_name }}
              <div class="rca" v-if="m.t2.rca && m.t2.rca!='0'">{{ m.t2.rca }}</div>
            </div>
          </td>
          
          <td class="score"><span class="score1"><span>{{ m.t1.s }}</span></span> - <span class="score2"><span>{{ m.t2.s }}</span></span></td>
        
          <td class="separator"><div>(<span class="score1_1">{{ m.t1.s1 }}</span> - <span class="score1_2">{{ m.t2.s1 }}</span>)</div><div>(<span class="score2_1">{{ m.t1.s2 }}</span> - <span class="score2_2">{{ m.t2.s2 }}</span>)</div></td>
          
          <td class="stvor stvor1" :class="{ more1: m.t1.stvor >= 4 }" title="Удары в створ / Удары в сторону ворот"><span>{{ m.t1.stvor }}</span> <div class="m vorota vorota1">{{ m.t1.vorota }}</div></td>
          <td class="stvor stvor2" :class="{ more1: m.t2.stvor >= 4 }" title="Удары в створ / Удары в сторону ворот"><span>{{ m.t2.stvor }}</span> <div class="m vorota vorota2">{{ m.t2.vorota }}</div></td>
          
          <td class="separator"></td>
          
          <td class="ugl ugl1" :class="{ more2: m.t1.ugl >= 7 }" title="Угловые">{{ m.t1.ugl }}</td>
          <td class="ugl ugl2" :class="{ more2: m.t2.ugl >= 7 }" title="Угловые">{{ m.t2.ugl }}</td>

          <td class="separator"></td>
          <td class="separator"></td>
          
          <td class="dat dat1" title="Атаки опасные / Атаки"><span>{{ m.t1.dat }}</span> <div class="m at at1">{{ m.t1.at }}</div></td>
          <td class="dat dat2" title="Атаки опасные / Атаки"><span>{{ m.t2.dat }}</span> <div class="m at at2">{{ m.t2.at }}</div></td>

          <td class="separator"></td>
          
          <td class="bp bp1" title="Владение мячом">{{ m.t1.bp }}</td>
          <td class="bp bp2" title="Владение мячом">{{ m.t2.bp }}</td>
          
          <td class="separator"></td>
          <td class="separator"></td>

          <td class="yca yca1" :class="{ colornormal: m.t1.yca >= 1 }" title="Желтые карточки">{{ m.t1.yca }}</td>
          <td class="yca yca2" :class="{ colornormal: m.t2.yca >= 1 }" title="Желтые карточки">{{ m.t2.yca }}</td>
          
          <td class="separator"></td>
          
          <td class="pen pen1" :class="{ boldred: m.t1.pen >= 1 }" title="Пенальти">{{ m.t1.pen }}</td>
          <td class="pen pen2" :class="{ boldred: m.t2.pen >= 1 }" title="Пенальти">{{ m.t2.pen }}</td>
          
          <td class="separator"></td>
          <td class="separator"></td>
          
          <td title="ТБ" class="t05"><span>{{ m.coeff.t05.c }}</span> <div class="m t05t">{{ m.coeff.t05.t }}</div></td>
          <td title="ТБ" class="t1"><span>{{ m.coeff.t1.c }}</span> <div class="m t1t">{{ m.coeff.t1.t }}</div></td>
          
          <td class="separator"></td>
          <td class="separator"></td>

          <td title="Победа 1" class="p1">{{ m.coeff.p1 }}</td>
          <td title="Ничья" class="x">{{ m.coeff.x }}</td>
          <td title="Победа 2" class="p2">{{ m.coeff.p2 }}</td>
          <td class="separator"></td>
          <td title="ТБ" class="tb"><span>{{ m.coeff.tb.c }}</span> <div class="m tbt">{{ m.coeff.tb.t }}</div></td>
          <td title="ТМ" class="tm"><span>{{ m.coeff.tm.c }}</span> <div class="m tmt">{{ m.coeff.tm.t }}</div></td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import BarCom from '@/com/main/BarCom.vue'

export default {
  components: {
    BarCom
  },

  created: function () {
    let t = this
    document.title = 'Live'
    
    this.dataGet(true)

    this.ws = new this.$Websocket(this, 'live')
    this.ws.onmessage = function(e) {
      if (e.data==='update') {
        t.dataGet()
      }
    }
    this.ws.open()

    document.addEventListener('visibilitychange', this.visibilitychange)
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilitychange)
  },

  computed: {
    filter: {
      get: function () { return this.$root.storage.live.filter },
      set: function (val) { this.$root.storage.live.filter = val }
    },
    favorites: {
      get: function () { return this.$root.storage.live.favorites },
      set: function (val) { this.$root.storage.live.favorites = val }
    }
  },

  data() { return {
    ws: null,
    matches: null
	}},

  methods: {

    visibilitychange: function() {
      if (!document.hidden) {
        this.dataGet()
      }
    },

    dataGet: function(loading) {
      let favorites
      if (this.filter == 'favorites') favorites = this.favorites
      let params = {filter: this.filter, id: favorites}

      this.$api({method: 'get', path: 'live/data', loading:loading, params: params}).then(response => {
        this.matches = response.data.data
      })
    },

    star: function(id) {
      let t = this
      function filterFavoritesOff() {
        if (t.filter == 'favorites') { t.filter = null; t.dataGet(true) }
      }
      
      if (!id) { this.favorites = []; filterFavoritesOff(); return null }
      
      let i = this.favorites.indexOf(id)
      if (i < 0) {
        this.favorites.push(id)
      } else {
        this.favorites.splice(i, 1)
        if (!this.favorites.length) filterFavoritesOff()
      }
    },

    setFilter: function(name) {
      let filter
      if (this.filter == name) {
        filter = null
      } else {
        filter = name
        if (filter == 'favorites' && !this.favorites.length) return null
      }
      this.filter = filter
      this.dataGet(true)
    }

  }

}
</script>
<style scoped lang="scss">@import './live.scss';</style>
<style lang="scss">
#Live #headerCom {
  .switch-shade {
    display: none;
  }
  .item.text {
    font-weight:700;
  }
}
</style>