<template>
  <div id="Tuning">
    <div class="group" v-for="(group, g) in dataBack" :key="g">
      <div class="group-title">{{ group.title }}</div>
      <table>
        <tr class="item" v-for="(item, i) in group.items" :key="i">
          <td class="item-title">{{ item.title }}</td>
          <td class="item-val">
            <div v-if="item.type == 'btn'" class="switch-btn" :class="{ on: item.val }" @click="$clickSound();itemToggle(g, i, item.val, item.type)"></div>
            <div v-if="item.type == 'info'">{{ item.val }}</div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

export default {

  created: function () {
    document.title = 'Настройки'
    this.dataBackGet(true)
    document.addEventListener('visibilitychange', this.visibilitychange)
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilitychange)
  },

  data() { return {
    dataBack: null
  }},

  methods: {

    visibilitychange: function() {
      if (!document.hidden) {
        this.dataBackGet()
      }
    },

    dataBackGet: function(loading) {
      this.$api({method: 'get', path: 'tuning', loading:loading}).then(response => {
        this.dataBack = response.data.data
      })
    },

    itemToggle: function(group, item, val, type) {
      const data = {group: group, item: item, val: !val, type: type}
      this.$api({method: 'put', path: 'tuning', data: data}).then(response => {
        this.dataBack = response.data.data
      })
    }

  }
}
</script>
<style scoped lang="scss">
#Tuning {
  padding: 30px;

  .group {
    margin-bottom: 30px;
    padding-bottom:10px;
    border-bottom:1px solid $bg-027;
    max-width:400px;
    
    &:last-of-type {
      margin:0;
      padding:0;
      border:none;
    }   
    .group-title {
      text-transform: uppercase;
      margin-bottom: 20px;
      color:$color-020;
    }
    .item {
      .item-title {
        padding: 0 30px 20px 0;
        color:$color-010;
      }
      .item-val {
        vertical-align: top;

        .switch-btn {
          position:relative;
        }
      }
    }
  }
}
</style>