<template>
  <div id="Odds">
    
    <BarCom>
      <template v-slot:header>
        <div class="item text pointer" :class="{ active: filter.filters.includes('x12') }" @click="$clickSound();setFilter('x12')">П</div>
        <div class="item text pointer" :class="{ active: filter.filters.includes('tbm') }" @click="$clickSound();setFilter('tbm')">Т</div>
        <div class="item icon pointer" :class="{ active: filter.filters.includes('favorites') }" @click="$clickSound();setFilter('favorites')"><i class="fas fa-star"></i></div>
        <div class="item input"><date-picker ref="datepickerDump" :input-attr=datePickerAttr v-model="filter.dump" type="datetime" value-type='format' format="YYYY-MM-DD-H" :class="{ active: filter.filters.includes('dump') }" v-on:change="changeDump"></date-picker></div>
        <div class="item text pointer" v-if="filter.filters.includes('dump')" @click="$clickSound();changeDumpHour('-')">-</div>
        <div class="item text pointer" v-if="filter.filters.includes('dump')" @click="$clickSound();changeDumpHour('+')">+</div>
        <div class="item icon pointer" @click="$clickSound();setFilter()"><i class="fas fa-times"></i></div>
        <div class="item text dumpdt" v-if="dumpDt">dump: {{ dumpDt }}</div>
        <div class="item text count" v-if="matches">events: {{ matches.length }}</div>
      </template>
      <template v-slot:shade>
        
      </template>
    </BarCom>

    <table class="datatable loading" v-if="matches">

      <thead>
        <tr>
          <th class="first"><i class="fas fa-star clear-favorites" title="Очистить избранное" v-if="filter.favorites.length" @click="$clickSound();star()"></i></th>
          <th><a :class="{active: order=='dt'}" @click="$clickSound();setOrder('dt')">DT</a></th>
          <th>Комманды</th>
          <td class="separator"></td>
          <th title="П1">П1</th>
          <th title="X">X</th>
          <th title="П2">П2</th>
          <td class="separator"></td>
          <th title="Объем 1x2"><a :class="{active: order=='-x12_total'}" @click="$clickSound();setOrder('-x12_total')">Объем 1x2</a></th>
          <td class="separator"></td>
          <td class="separator"></td>
          <th title="ТМ">ТМ</th>
          <th></th>
          <th title="ТБ">ТБ</th>
          <td class="separator"></td>
          <th title="Объем ТБМ"><a :class="{active: order=='-tbm_total'}" @click="$clickSound();setOrder('-tbm_total')">Объем ТБМ</a></th>
          <td class="separator"></td>
          <td class="separator"></td>
          <th title="Объем общий"><a :class="{active: order=='-total'}" @click="$clickSound();setOrder('-total')">Объем общий</a></th>
        </tr>
      </thead>
      <tbody v-for="m in matches" :key="m.id" >
        <tr class="separator">
          <td colspan="5" class="league">{{ m.league }}</td>
          <td v-for="i in 11" :key="i"></td>
        </tr>
        <tr class="match">
          <td class="date pointer" title="В избранное" @click="$clickSound();star(m.id)">{{ m.date }}<i class="fas fa-star favorites" v-if="filter.favorites.includes(m.id)"></i></td>
          <td class="time">{{ m.time }}</td>
          <td class="teams">
            <div class="team team1">
              {{ m.team1 }}
            </div>
            <div class="team team2">
              {{ m.team2 }}
            </div>
          </td>
          <td class="separator"></td>
          <td title="П1" class="p1" :class="m.x12_percent[0][1]"><span>{{ m.x12_coeff[0] }}</span> <div class="m">{{ m.x12_money[0] }} {{ m.x12_percent[0][0] }}</div></td>
          <td title="X" class="x" :class="m.x12_percent[1][1]"><span>{{ m.x12_coeff[1] }}</span> <div class="m">{{ m.x12_money[1] }} {{ m.x12_percent[1][0] }}</div></td>
          <td title="П2" class="p2" :class="m.x12_percent[2][1]"><span>{{ m.x12_coeff[2] }}</span> <div class="m">{{ m.x12_money[2] }} {{ m.x12_percent[2][0] }}</div></td>
          <td class="separator"></td>
          <td title=""><span v-if="m.x12_total">{{ m.x12_total }} €</span></td>
          <td class="separator"></td>
          <td class="separator"></td>
          <td title="ТМ" :class="m.tbm_percent[0][1]"><span>{{ m.tbm_coeff[0] }}</span> <div class="m">{{ m.tbm_money[0] }} {{ m.tbm_percent[0][0] }}</div></td>
          <td><span>{{ m.tbm_coeff[1] }}</span></td>
          <td title="ТБ" :class="m.tbm_percent[1][1]"><span>{{ m.tbm_coeff[2] }}</span> <div class="m">{{ m.tbm_money[1] }} {{ m.tbm_percent[1][0] }}</div></td>
          <td class="separator"></td>
          <td title=""><span v-if="m.tbm_total">{{ m.tbm_total }} €</span></td>
          <td class="separator"></td>
          <td class="separator"></td>
          <td title=""><span v-if="m.total">{{ m.total }} €</span></td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import BarCom from '@/com/main/BarCom.vue'
import DatePicker from 'vue2-datepicker'
import '@/assets/datepicker.scss'
import 'vue2-datepicker/locale/ru'

export default {
  components: {
    BarCom,
    DatePicker
  },

  created: function () {
    let t = this
    document.title = 'Прогрузы'
    
    this.dataGet(true)

    this.ws = new this.$Websocket(this, 'odds')
    this.ws.onmessage = function(e) {
      if (e.data==='update') {
        t.dataGet()
      }
    }
    this.ws.open()

    document.addEventListener('visibilitychange', this.visibilitychange)
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilitychange)
  },

  computed: {
    filter: {
      get: function () { return this.$root.storage.odds.filter },
      set: function (val) { this.$root.storage.odds.filter = val }
    },
    order: {
      get: function () { return this.$root.storage.odds.order },
      set: function (val) { this.$root.storage.odds.order = val }
    }
  },

  data() { return {
    ws: null,
    matches: null,
    dumpDt: null,
    datePickerAttr: {readonly: true}
	}},

  methods: {
    
    visibilitychange: function() {
      if (!document.hidden) {
        this.dataGet()
      }
    },

    dataGet: function(loading) {
      let params = {filter: this.filter, order:this.order}
      this.$api({method: 'get', path: 'odds/data', loading:loading, params: params}).then(response => {
        if (response.data.data) {
          this.matches = response.data.data.matches
          this.dumpDt = response.data.data.dt
        } else {
          this.matches = null
          this.dumpDt = null
        }
      })
    },

    star: function(id) {
      let t = this
      function filterFavoritesOff() {
        if (t.filter.filters.includes('favorites')) {
          t.filter.filters.splice(t.filter.filters.indexOf('favorites'), 1)
          t.dataGet(true)
        }
      }
      
      if (!id) { this.filter.favorites = []; filterFavoritesOff(); return null }
      
      let i = this.filter.favorites.indexOf(id)
      if (i < 0) {
        this.filter.favorites.push(id)
      } else {
        this.filter.favorites.splice(i, 1)
        if (!this.filter.favorites.length) filterFavoritesOff()
      }
    },

    setFilter: function(name) {
      if (!name) {
        this.filter.filters = []
        this.filter.dump = null
        this.dataGet(true)
        return null
      }

      if (name == 'favorites' && !this.filter.favorites.length) return null

      if (this.filter.filters.includes(name)) {
        if (name != 'dump') this.filter.filters.splice(this.filter.filters.indexOf(name), 1)
      } else {
        this.filter.filters.push(name)
      }
      
      this.dataGet(true)
    },

    setOrder: function(name) {
      if (this.order == name) {
        return null
      } else {
        this.order = name
        this.dataGet(true)
      }
    },

    changeDump: function(date, type) {
      if (type == 'hour') {
        this.setFilter('dump')
        this.$refs.datepickerDump.closePopup()
      }
    },

    changeDumpHour: function(p) {
      let d = this.filter.dump.split('-')
      let h = Number.parseInt(d[3])
      if (p == '+') h += 1
      if (p == '-') h -= 1
      let v = d[0] + '-' + d[1] + '-' + d[2] + '-' + h
      this.filter.dump = v
      this.dataGet(true)
    }

  }

}
</script>
<style scoped lang="scss">@import './odds.scss';</style>
<style lang="scss">
#Odds #headerCom {
  .switch-shade {
    display: none;
  }
  .item.text {
    font-weight:700;
  }
  .item.text.count {
    font-weight:400;
    font-size:0.875rem;
    color:$bg-301;
  }
  .item.text.dumpdt {
    font-weight:400;
    font-size:0.875rem;
    color:$color-037;
  }

  .input .mx-icon-calendar,
  .input .mx-icon-clear {
    display: none;
  }
  .input input {
    width: 107px;
  }
  .input .active input {
      color:$bg-101;
    }
}
</style>